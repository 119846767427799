import { Link } from "react-router-dom";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  downloadCSV,
  Edit,
  FunctionField,
  List,
  ListActions,
  NumberField,
  NumberInput,
  RecordContextProvider,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreatePath,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useStore,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import inflection from "inflection";
import jsonExport from "jsonexport/dist";

import { BulkDeleteButton } from "./BulkDeleteButton";
import { BulkExportButton } from "./BulkExportButton";

const UsesField = (props) => {
  const record = useRecordContext(props);
  if (!record) return null;
  if (!record.max_uses)
    return (
      <span
        style={{
          color: "green",
          fontWeight: "bold",
        }}
      >
        {record.uses}
      </span>
    );
  return (
    <span
      style={{
        color: record.uses < record.max_uses ? "green" : "red",
        fontWeight: "bold",
      }}
    >
      {record.uses} / {record.max_uses}
    </span>
  );
};

const ReferralCodeListAsideCard = (props) => {
  const theme = useTheme();
  return (
    <Card sx={{ mt: "8px", mb: "3em", ml: "1em", width: 300 }}>
      <CardHeader
        action={props.action}
        sx={{
          backgroundColor: theme.palette.yellow.main,
          py: props.action ? "3px" : "7px",
        }}
        title={props.title}
        titleTypographyProps={{
          variant: "span",
          fontWeight: "bold",
        }}
      />
      <CardContent
        sx={{
          "& .RaLabeled-label": { fontWeight: "bold" },
          "& fieldset": {
            borderColor: theme.palette.yellow.main,
            borderRadius: "12px",
          },
          "& legend": {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
      >
        {props.children || <Stack spacing={1} />}
      </CardContent>
    </Card>
  );
};

const ReferralCodeListAside = (props) => {
  const { data, isPending, selectedIds } = useListContext();
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  if (isPending) return null;
  if (!data) return null;
  if (!data.length) return null;
  const record = data?.find((e) => e.id === props.selection);
  if (!record)
    return (
      <ReferralCodeListAsideCard
        title={`${selectedIds.length} ${inflection.inflect("referral code", selectedIds.length)} selected`}
      />
    );
  if (!props.selection)
    return <ReferralCodeListAsideCard title="No referral code selected" />;
  return (
    <RecordContextProvider value={record}>
      <ReferralCodeListAsideCard
        action={
          <IconButton
            component={Link}
            to={createPath({ type: "edit", resource, id: record.id })}
            color="black"
          >
            <EditOutlinedIcon />
          </IconButton>
        }
        title={record.code}
      >
        <Stack spacing={1}>
          <Box component="fieldset">
            <legend>Id</legend>
            <TextField source="id" />
          </Box>
          {record.description && (
            <Box component="fieldset">
              <legend>Description</legend>
              <TextField source="description" />
            </Box>
          )}
          {record.expiry_date && (
            <Box component="fieldset">
              <legend>Expiry Date</legend>
              <DateField source="expiry_date" />
            </Box>
          )}
          {record.max_uses && (
            <Box component="fieldset">
              <legend>Max Uses</legend>
              <NumberField source="max_uses" />
            </Box>
          )}
          <Box component="fieldset">
            <legend>Organization</legend>
            <ReferenceField
              source="organization"
              emptyText="No organization"
              reference="organizations"
            >
              <TextField source="name" />
            </ReferenceField>
          </Box>
          <Box component="fieldset">
            <legend>Status</legend>
            <FunctionField
              source="is_active"
              render={(record) => (record.is_active ? "Active" : "Inactive")}
            />
          </Box>
        </Stack>
      </ReferralCodeListAsideCard>
    </RecordContextProvider>
  );
};

const exporter = async (referralCodes, fetchRelatedRecords) => {
  const organizations = await fetchRelatedRecords(referralCodes, "organization", "organizations");
  const referralCodesForExport = referralCodes.map((referralCode) => {
    const { code, description, uses, is_active, expiry_date } = referralCode;
    return {
      code,
      description,
      organization: organizations[referralCode.organization]?.name,
      uses,
      is_active,
      expiry_date: expiry_date && Date(expiry_date).toString(),
    };
  });
  jsonExport(
    referralCodesForExport,
    {
      headers: ["code", "description", "organization", "uses", "is_active", "expiry_date"],
      rename: ["Code", "Description", "Organization", "Uses", "Status", "Expiry Date"],
    },
    (err, csv) => {
      downloadCSV(csv, "referralCodes"); // download as 'referralCodes.csv` file
    },
  );
};

export const ReferralCodeList = (props) => {
  const [selection, setSelection] = useStore(
    props.selectionStore ?? "referralCode.list",
    null,
  );
  const theme = useTheme();

  return (
    <List
      {...props}
      // biome-ignore lint/complexity/noUselessFragments: Hide filter button
      actions={<ListActions filters={<></>} />}
      aside={<ReferralCodeListAside selection={selection} />}
      exporter={exporter}
      filters={[<SearchInput key="search" source="search" alwaysOn />]}
      sort={{ field: "code", order: "ASC" }}
      sx={{ m: 2 }}
    >
      <Datagrid
        bulkActionButtons={
          <>
            <BulkExportButton color="white" />
            <BulkDeleteButton
              mutationMode="pessimistic"
              color="white"
              deleteSuccessMessage="Referral code deleted |||| %{smart_count} referral codes deleted"
            />
          </>
        }
        rowClick={(id, resource, record) => {
          setSelection(id);
          return false;
        }}
        rowSx={(record, index) => ({
          backgroundColor:
            record.id === selection ? theme.palette.lightYellow.main : null,
        })}
      >
        <TextField source="code" />
        <TextField source="description" />
        <ReferenceField source="organization" reference="organizations">
          <TextField source="name" />
        </ReferenceField>
        <UsesField source="uses" />
        <BooleanField
          source="is_active"
          label="Status"
          TrueIcon={CircleIcon}
          FalseIcon={null}
          sx={{ color: "green" }}
        />
        <DateField source="expiry_date" />
      </Datagrid>
    </List>
  );
};

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = (props) => {
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  return (
    <Button
      component={Link}
      to={createPath({ type: "list", resource })}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const ReferralCodeForm = (props) => {
  const { permissions } = usePermissions();
  return (
    <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />}>
      <TextInput source="code" fullWidth required />
      <TextInput source="description" fullWidth multiline />
      <ReferenceInput source="organization" fullWidth reference="organizations">
        <AutocompleteInput
          optionText="name"
          fullWidth
          validate={!permissions.canEdit && required()}
        />
      </ReferenceInput>
      <Divider flexItem sx={{ my: 1 }} />
      <Typography variant="span">Rules</Typography>
      <NumberInput
        source="max_uses"
        fullWidth
        min={1}
        helperText="Leave blank for unlimited uses."
      />
      <DateTimeInput
        source="expiry_date"
        fullWidth
        label="Expiry Date"
        helperText="Leave blank to never expire."
      />
      <BooleanInput source="is_active" label="Active" fullWidth />
    </SimpleForm>
  );
};

export const ReferralCodeCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Referral code created.");
    redirect("list", "referral-codes");
  };

  const onError = (error) => {
    notify("Could not create referral code.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Create
          title="New Code"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Referral code"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <ReferralCodeForm />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const ReferralCodeEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Referral code updated.");
    redirect("list", "referral-codes");
  };

  const onError = (error) => {
    notify("Could not update referral code.", { type: "error" });
  };

  return (
    <Container maxWidth={false} sx={{ my: { xs: 3 }, px: { xs: 0 } }}>
      <BackButton />
      <Container maxWidth="sm" sx={{}}>
        <Edit
          title="Edit Code"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Referral code"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <ReferralCodeForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
