import { forwardRef, useState } from "react";
import {
  Menu as AdminMenu,
  TitlePortal,
  useCreatePath,
  useGetResourceLabel,
  useLogout,
  usePermissions,
  useResourceDefinitions,
} from "react-admin";
import { AppBar, Box, Button, Icon, Menu, MenuItem, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { UserMenu } from "./UserMenu";

const Logout = forwardRef((props, ref) => {
  const logout = useLogout();
  return (
    <MenuItem onClick={logout} ref={ref} {...props}>
      Logout
    </MenuItem>
  );
});

const ResourceMenuItems = (props) => {
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  return (
    <>
      {Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => (
          // <AdminMenu.ResourceItem key={name} name={name} />
          <AdminMenu.Item
            key={name}
            to={createPath({
              resource: name,
              type: "list",
            })}
            state={{ _scrollToTop: true }}
            primaryText={getResourceLabel(name, 2)}
            leftIcon={<Icon />}
            onClick={props.onClick}
          />
        ))}
    </>
  );
};

const CustomAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { permissions } = usePermissions();
  const theme = useTheme();

  const open = Boolean(anchorEl);
  const isAdmin = permissions.canCreateUser;
  const menuWidth = "240px";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar color="yellow">
      <Toolbar disableGutters>
        <div>
          <Button
            variant="contained"
            edge="start"
            color="white"
            aria-label="menu"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: open ? 0 : 10,
              width: menuWidth,
            }}
          >
            <MenuIcon />
            <TitlePortal
              sx={{
                ml: 2,
                pointerEvents: "none",
                textAlign: "left",
                textTransform: "none",
              }}
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            marginThreshold={0}
            open={open}
            onClose={handleClose}
            aria-labelledby="basic-button"
            PaperProps={{
              sx: {
                backgroundColor: theme.palette.white.main,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
                width: menuWidth,
              },
            }}
          >
            {isAdmin && (
              <AdminMenu.DashboardItem
                leftIcon={<Icon />}
                onClick={handleClose}
              />
            )}
            <ResourceMenuItems onClick={handleClose} />
          </Menu>
        </div>
        <Box flex="1" />
        <UserMenu>
          <Logout />
        </UserMenu>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
