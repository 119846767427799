const apiUrl = `${process.env.REACT_APP_API_URL}/admin-api`;

const authProvider = {
  login: async ({ email, password }) => {
    const credentials = btoa(`${email}:${password}`);
    const authHeader = `Basic ${credentials}`;
    const request = new Request(`${apiUrl}/login/`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader,
      }),
      body: JSON.stringify({ email, password }),
    });

    return fetch(request)
      .then((response) => {
        if (!response.ok) throw new Error("Incorrect credentials");
        return response.json();
      })
      .then((auth) => {
        if (auth.token && auth.account_type) {
          localStorage.setItem("token", auth.token);
          if (auth.is_superuser) {
            localStorage.setItem("account_type", "super");
          } else if (auth.is_staff) {
            localStorage.setItem("account_type", "admin");
          } else {
            localStorage.setItem("account_type", "cms");
            localStorage.setItem("groups", auth.groups);
          }
          localStorage.setItem("id", auth.id);
          localStorage.setItem("name", `${auth.first_name ?? ''} ${auth.last_name ?? ''}`);
          // Trigger an event to update permissions across tabs
          window.dispatchEvent(new Event("storage"));
          return Promise.resolve();
        }
        throw new Error("No token returned");
      });
  },
  logout: () => {
    const authHeader = `Token ${localStorage.getItem("token")}`;
    const request = new Request(`${apiUrl}/logout/`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader,
      }),
    });
    fetch(request);
    localStorage.removeItem("token");
    localStorage.removeItem("account_type"); // Also clear account_type on logout
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    window.dispatchEvent(new Event("storage")); // Update permissions on logout across tabs
    // localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("account_type"); // Clear on auth error as well
      return Promise.reject(new Error("Authorization failed"));
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const type = localStorage.getItem("account_type");
    let perms = {};
    switch (type) {
      case "super":
        perms = {
          canCreateAdmin: true,
          canCreateUser: true,
          canEdit: true,
          canDelete: true,
        };
        break;
      case "admin":
        perms = {
          canCreateAdmin: false,
          canCreateUser: true,
          canEdit: true,
          canDelete: false,
        };
        break;
      case "cms":
        perms = {
          canCreateAdmin: false,
          canCreateUser: false,
          canEdit: false,
          canDelete: false,
        };
        break;
      default:
        perms = {
          canCreateAdmin: false,
          canCreateUser: false,
          canEdit: false,
          canDelete: false,
          isRestrict: false,
        };
    }
    return Promise.resolve(perms);
  },
  getIdentity: () => {
    try {
      const id = localStorage.getItem("id");
      const fullName = localStorage.getItem("name");
      return Promise.resolve({ id, fullName });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
