// Copied to allow more styling
// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/layout/UserMenu.tsx

import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { useMeasure } from "@uidotdev/usehooks";
import {
  Logout,
  useAuthProvider,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import { Avatar, Button, Menu, Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { UserMenuContextProvider } from "./UserMenuContextProvider";

export const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [ref, { width }] = useMeasure();
  const translate = useTranslate();
  const { isPending, identity } = useGetIdentity();
  const authProvider = useAuthProvider();
  const isLargeEnough = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const theme = useTheme();

  const {
    children = authProvider ? <Logout /> : null,
    className,
    label = "ra.auth.user_menu",
    icon = defaultIcon,
  } = props;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const context = useMemo(() => ({ onClose: handleClose }), [handleClose]);
  if (!children) return null;
  const open = Boolean(anchorEl);
  const menuWidth = "160px";

  return (
    <Root className={className}>
      {isLargeEnough && !isPending && identity?.fullName ? (
        <Button
          ref={ref}
          aria-label={label && translate(label, { _: label })}
          className={UserMenuClasses.userButton}
          color="white"
          endIcon={
            identity.avatar ? (
              <Avatar
                className={UserMenuClasses.avatar}
                src={identity.avatar}
                alt={identity.fullName}
              />
            ) : (
              icon
            )
          }
          onClick={handleMenu}
          variant="contained"
          size="large"
          sx={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: open ? 0 : 10,
            borderBottomRightRadius: 0,
            justifyContent: "right",
            maxLines: 1,
            minWidth: menuWidth,
          }}
        >
          {identity.fullName}
        </Button>
      ) : (
        <Tooltip title={label && translate(label, { _: "Profile" })}>
          <Button
            aria-label={label && translate(label, { _: "Profile" })}
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup={true}
            color="white"
            variant="contained"
            size="large"
            onClick={handleMenu}
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 0,
            }}
          >
            {!isPending && identity?.avatar ? (
              <Avatar
                className={UserMenuClasses.avatar}
                src={identity.avatar}
                alt={identity.fullName}
              />
            ) : (
              icon
            )}
          </Button>
        </Tooltip>
      )}
      <UserMenuContextProvider value={context}>
        <Menu
          id="menu-appbar"
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={AnchorOrigin}
          marginThreshold={0}
          transformOrigin={TransformOrigin}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.white.main,
              borderTopLeftRadius: isLargeEnough ? 0 : null,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              minWidth: menuWidth,
              width: isLargeEnough ? width : menuWidth,
            }
          }}
        >
          {children}
        </Menu>
      </UserMenuContextProvider>
    </Root>
  );
};

const PREFIX = "RaUserMenu";

export const UserMenuClasses = {
  userButton: `${PREFIX}-userButton`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${UserMenuClasses.userButton}`]: {
    textTransform: "none",
    marginInlineStart: theme.spacing(1),
  },

  [`& .${UserMenuClasses.avatar}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const defaultIcon = <AccountCircleOutlinedIcon />;

const AnchorOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const TransformOrigin = {
  vertical: "top",
  horizontal: "right",
};
