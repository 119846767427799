import { Layout } from "react-admin";
import AppBar from "./AppBar";

const CustomLayout = ({ children }) => (
  <Layout
    appBar={AppBar}
    sidebar={() => <></>}
    sx={{
      "& .RaLayout-appFrame": { mt: 8 },
      "& .RaLayout-content": { px: 0 },
    }}
  >
    {children}
  </Layout>
);

export default CustomLayout;
