import axios from "axios";
import queryString from "query-string";
import { HttpError } from "react-admin";

const apiUrl = `${process.env.REACT_APP_API_URL}/admin-api`;

// Stolen from https://github.com/bmihelac/ra-data-django-rest-framework/blob/master/src/index.ts
const getPaginationQuery = (pagination) => {
  return {
    page: pagination.page,
    page_size: pagination.perPage,
  };
};

const getFilterQuery = (filter) => {
  const { search, ...otherSearchParams } = filter;
  return {
    ...otherSearchParams,
    search,
  };
};

const getOrderingQuery = (sort) => {
  const { field, order } = sort;
  return {
    ordering: `${order === "ASC" ? "" : "-"}${field}`,
  };
};

// Customized httpClient using Axios
const httpClient = async (url, method = "GET", data = null) => {
  const headers = {
    Accept: "application/json",
  };

  const token = localStorage.getItem("token");
  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  const axiosConfig = {
    method: method,
    url: url,
    headers: headers,
    data: data,
  };

  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    console.error("API call failed:", error.response || error.message);
    throw error;
  }
};

// Data Provider object
const dataProvider = {
  getList: async (resource, params) => {
    const query = {
      ...getFilterQuery(params.filter),
      ...getPaginationQuery(params.pagination),
      ...getOrderingQuery(params.sort),
    };
    const url = `${apiUrl}/${resource}/?${queryString.stringify(query)}`;
    const data = await httpClient(url);
    return {
      data: data.results.map((record) => ({
        ...record,
        id: record.id,
      })),
      total: data?.count ? data.count : 0,
    };
  },
  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const data = await httpClient(url);
    return {
      data: { ...data, id: data.id },
    };
  },
  getMany: async (resource, params) => {
    const data = await Promise.all(
      params.ids.map((id) => {
        const url = `${apiUrl}/${resource}/${id}/`;
        return httpClient(url);
      }),
    );
    return {
      data: data.map((item) => ({ ...item, id: item.id })),
    };
  },
  getManyReference: async (resource, params) => {
    const query = {
      ...getFilterQuery(params.filter),
      ...getPaginationQuery(params.pagination),
      ...getOrderingQuery(params.sort),
      [params.target]: params.id,
    };
    const url = `${apiUrl}/${resource}/?${queryString.stringify(query)}`;
    const data = await httpClient(url);
    return {
      data: data.results.map((record) => ({
        ...record,
        id: record.id,
      })),
      total: data?.count ? data.count : 0,
    };
  },
  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}/`;
    try {
      const data = await httpClient(url, "PATCH", params.data);
      return {
        data: { ...data, id: data.id },
      };
    } catch (e) {
      const body = e.response.data;
      for (const key in body) {
        if (Object.hasOwn(body, key)) {
          body[key] = body[key][0];
        }
      }
      throw new HttpError(e.message, e.response.status, {
        errors: e.response.data,
      });
    }
  },
  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}/`;
    try {
      const data = await httpClient(url, "POST", params.data);
      return {
        data: { ...data, id: data.id },
      };
    } catch (e) {
      const body = e.response.data;
      for (const key in body) {
        if (Object.hasOwn(body, key)) {
          body[key] = body[key][0];
        }
      }
      throw new HttpError(e.message, e.response.status, {
        errors: e.response.data,
      });
    }
  },
  delete: async (resource, params) => {
    const url = params.meta?.hardDelete
      ? `${apiUrl}/${resource}/${params.id}/hard-delete/`
      : `${apiUrl}/${resource}/${params.id}/`;
    await httpClient(url, "DELETE");
    return {
      data: { id: params.id },
    };
  },
  deleteMany: async (resource, params) => {
    const data = await Promise.all(
      params.ids.map((id) => {
        const url = params.meta?.hardDelete
          ? `${apiUrl}/${resource}/${id}/hard-delete/`
          : `${apiUrl}/${resource}/${id}/`;
        return httpClient(url, "DELETE");
      }),
    );
    return {
      data: data.map((item) => ({ id: item.id })),
    };
  },
  getChildOfUser: async (userId) => {
    const url = `${apiUrl}/users/${userId}/child`;
    const data = await httpClient(url);
    return { data: data }; // directly return the response as data
  },
  changePassword: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/set-password/`;
    const data = await httpClient(url, "PUT", params.data);
    return { data: data };
  },
  addUserToOrganization: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/organizations/`;
    const data = await httpClient(url, "POST", params.data);
    return { data: data };
  },
  removeUserFromOrganization: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/organizations/`;
    const data = await httpClient(url, "DELETE", params.data);
    return { data: data };
  },
  addUserToGroup: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/groups/`;
    const data = await httpClient(url, "POST", params.data);
    return { data: data };
  },
  removeUserFromGroup: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/groups/`;
    const data = await httpClient(url, "DELETE", params.data);
    return { data: data };
  },
  removeFacebookIntegration: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/integrations/facebook/`;
    const data = await httpClient(url, "DELETE");
    return { data: data };
  },
  removeGoogleIntegration: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/integrations/google/`;
    const data = await httpClient(url, "DELETE");
    return { data: data };
  },
  removeAppleIntegration: async (userId, params) => {
    const url = `${apiUrl}/users/${userId}/integrations/apple/`;
    const data = await httpClient(url, "DELETE");
    return { data: data };
  },
};

export default dataProvider;
