import { defaultTheme } from "react-admin";
import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  // ...defaultTheme,
  palette: {
    primary: {
      main: "#030C68",
      contrastText: "#EDF8FE",
    },
    yellow: {
      main: "#FFDE59",
      contrastText: "#030C68",
    },
    blue: {
      main: "#0078D4",
      contrastText: "#ECEFF1",
    },
    blue2: {
      main: "#00A3FF",
      contrastText: "#EDF8FE",
    },
    pink: {
      main: "#e91e63",
      contrastText: "#EDF8FE",
    },
    red: {
      main: "#DF0015",
      contrastText: "#ECEFF1",
    },
    lightYellow: {
      main: "#FDF6D9",
      contrastText: "#030C68",
    },
    success: {
      main: "#007328",
    },
    error: {
      main: "#B50000",
    },
    text: {
      primary: "#030C68",
      secondary: "#030C68",
    },
    black: {
      main: "#030C68",
      contrastText: "#EDF8FE",
    },
    white: {
      main: "#EDF8FE",
      contrastText: "#030C68",
    },
  },
  typography: {
    fontFamily: [
      "Quicksand",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme = createTheme(theme, {
  components: {
    ...defaultTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: "black",
        "&$checked": {
          color: "black",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          "& .RaList-content": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          "& .RaList-actions": {
            marginBottom: "8px",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-checkbox": {
            color: theme.palette.black.main,
          },
          "& .RaDatagrid-checkbox .Mui-checked": {
            color: theme.palette.black.main,
          },
          "& .RaDatagrid-headerCell": {
            backgroundColor: theme.palette.yellow.main,
            fontWeight: "bold",
          },
          "& .RaDatagrid-headerCell:first-of-type": {
            borderTopLeftRadius: "24px",
          },
          "& .RaDatagrid-headerCell:last-of-type": {
            borderTopRightRadius: "24px",
          },
          "& .MuiTableRow-root:hover": {
            backgroundColor: theme.palette.lightYellow.main,
          },
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          bottom: "8px",
          color: theme.palette.black.contrastText,
          "& .RaBulkActionsToolbar-topToolbar": {
            paddingBottom: "4px",
          },
          "& .RaBulkActionsToolbar-toolbar": {
            backgroundColor: theme.palette.black.main,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            color: theme.palette.black.contrastText,
          },
          "& .RaBulkActionsToolbar-icon": {
            color: theme.palette.black.contrastText,
          },
          "& .RaBulkActionsToolbar-title .MuiTypography-root": {
            fontWeight: "bold",
          },
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
  },
});

export const darkTheme = createTheme({
  ...theme,
  components: {
    ...theme.components,
  },
  palette: {
    ...theme.palette,
    mode: "dark",
  },
});
