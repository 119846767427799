import { styled } from "@mui/material/styles";
import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import { useTranslate } from "react-admin";
import logo from '../images/picatalk_logo.png';

export const Confirm = (props) => {
  const {
    className,
    isOpen = false,
    loading,
    content,
    cancel = "No",
    confirm = "Yes",
    onClose,
    onConfirm,
    translateOptions = {},
    ...rest
  } = props;

  const translate = useTranslate();

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(e);
    },
    [onConfirm],
  );

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <StyledDialog
      className={className}
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
      {...rest}
    >
      <DialogTitle
        id="alert-dialog-title"
        component="img"
        sx={{
          width: 128,
          height: 128,
          mt: -13,
          mx: 'auto',
          textAlign: 'center',
        }}
        alt="Pic-A-Talk Logo"
        src={logo}
      />
      <DialogContent>
        {typeof content === "string" ? (
          <DialogContentText>
            {translate(content, {
              _: content,
              ...translateOptions,
            })}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-evenly", pb: 4 }}>
        <Button
          color="lightYellow"
          variant="contained"
          disabled={loading}
          onClick={handleConfirm}
          autoFocus
        >
          {translate(confirm, { _: confirm })}
        </Button>
        <Button
          color="lightYellow"
          variant="contained"
          disabled={loading}
          onClick={onClose}
        >
          {translate(cancel, { _: cancel })}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

const PREFIX = "RaConfirm";

export const ConfirmClasses = {
  confirmPrimary: `${PREFIX}-confirmPrimary`,
  confirmWarning: `${PREFIX}-confirmWarning`,
};

const StyledDialog = styled(Dialog, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: theme.palette.white.main,
    overflow: "visible",
  },
  [`& .${ConfirmClasses.confirmPrimary}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${ConfirmClasses.confirmWarning}`]: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));
